
export default {

    install(Vue) {

        Vue.prototype.getCodeDateUtil = function (type) {
            var commonArray
            if (type == "itentityCodeType") {
                commonArray = [
                    //{ optionValue: "第一類", optionText: "受僱者、雇主或自營業主，以及專門職業及技術人員自行執業者" },
                    //{ optionValue: "第二類", optionText: "無一定雇主或自營作業而參加職業工會者；或參加海員總工會或船長公會為會員之外僱船員" },
                    //{ optionValue: "第三類", optionText: "農會及水利會會員，或年滿15歲以上實際從事農業工作者；或無一定雇主或自營作業而參加漁會為甲類會員；或年滿15歲以上實際從事漁業工作者" },
                    //{ optionValue: "第四類", optionText: "義務役軍人、軍校軍費生、在卹遺眷；替代役役男；或矯正機關之收容人" },
                    //{ optionValue: "第五類", optionText: "合於社會救助法規定之低收入戶成員" },
                    //{ optionValue: "第六類", optionText: "榮民及榮民遺眷之家戶代表；或不屬於前面所列各類被保險人及其眷屬之其他家戶戶長或代表" },
                    { optionValue: "原住民", optionText: "原住民" }];
                return commonArray;
            } else if (type == 'bloodType') {
                commonArray = [
                    { optionValue: "A", optionText: "A型" },
                    { optionValue: "B", optionText: "B型" },
                    { optionValue: "O", optionText: "O型" },
                    { optionValue: "AB", optionText: "AB型" },
                    { optionValue: "RA", optionText: "RH+" },
                    { optionValue: "RD", optionText: "RH-" },
                    { optionValue: "N", optionText: "不知道" }];
                return commonArray;
            } else if (type == 'genderType') {
                commonArray = [
                    { optionValue: "M", optionText: "男" },
                    { optionValue: "F", optionText: "女" },
                    { optionValue: "O", optionText: "其他" }];
                return commonArray;
            } else if (type == 'mealType') {
                commonArray = [
                    { optionValue: "M", optionText: "葷" },
                    { optionValue: "V", optionText: "素" },
                    { optionValue: "N", optionText: "不需要" }];
                return commonArray;
            } else if (type == 'district') {
                commonArray = [{
                    id: "臺北市",
                    name: "臺北市",
                    children: [{ id: "臺北市-中正區", name: "中正區" },
                    { id: "臺北市-大同區", name: "大同區" },
                    { id: "臺北市-中山區", name: "中山區" },
                    { id: "臺北市-松山區", name: "松山區" },
                    { id: "臺北市-大安區", name: "大安區" },
                    { id: "臺北市-萬華區", name: "萬華區" },
                    { id: "臺北市-信義區", name: "信義區" },
                    { id: "臺北市-士林區", name: "士林區" },
                    { id: "臺北市-北投區", name: "北投區" },
                    { id: "臺北市-內湖區", name: "內湖區" },
                    { id: "臺北市-南港區", name: "南港區" },
                    { id: "臺北市-文山區", name: "文山區" }]
                }, {
                    id: "基隆市",
                    name: "基隆市",
                    children: [{ id: "基隆市-仁愛區", name: "仁愛區" },
                    { id: "基隆市-信義區", name: "信義區" },
                    { id: "基隆市-中正區", name: "中正區" },
                    { id: "基隆市-中山區", name: "中山區" },
                    { id: "基隆市-安樂區", name: "安樂區" },
                    { id: "基隆市-暖暖區", name: "暖暖區" },
                    { id: "基隆市-七堵區", name: "七堵區" }]
                }, {
                    id: "新北市",
                    name: "新北市",
                    children: [{ id: "新北市-萬里區", name: "萬里區" },
                    { id: "新北市-金山區", name: "金山區" },
                    { id: "新北市-板橋區", name: "板橋區" },
                    { id: "新北市-汐止區", name: "汐止區" },
                    { id: "新北市-深坑區", name: "汐止區" },
                    { id: "新北市-石碇區", name: "石碇區" },
                    { id: "新北市-瑞芳區", name: "瑞芳區" },
                    { id: "新北市-平溪區", name: "平溪區" },
                    { id: "新北市-雙溪區", name: "雙溪區" },
                    { id: "新北市-貢寮區", name: "貢寮區" },
                    { id: "新北市-新店區", name: "新店區" },
                    { id: "新北市-坪林區", name: "坪林區" },
                    { id: "新北市-烏來區", name: "烏來區" },
                    { id: "新北市-永和區", name: "永和區" },
                    { id: "新北市-中和區", name: "中和區" },
                    { id: "新北市-土城區", name: "土城區" },
                    { id: "新北市-三峽區", name: "三峽區" },
                    { id: "新北市-樹林區", name: "樹林區" },
                    { id: "新北市-鶯歌區", name: "鶯歌區" },
                    { id: "新北市-三重區", name: "三重區" },
                    { id: "新北市-新莊區", name: "新莊區" },
                    { id: "新北市-泰山區", name: "泰山區" },
                    { id: "新北市-林口區", name: "林口區" },
                    { id: "新北市-蘆洲區", name: "蘆洲區" },
                    { id: "新北市-五股區", name: "五股區" },
                    { id: "新北市-八里區", name: "八里區" },
                    { id: "新北市-淡水區", name: "淡水區" },
                    { id: "新北市-三芝區", name: "三芝區" },
                    { id: "新北市-石門區", name: "石門區" }
                    ]
                }, {
                    id: "連江縣",
                    name: "連江縣",
                    children: [{ id: "連江縣-南竿鄉", name: "南竿鄉" },
                    { id: "連江縣-北竿鄉", name: "北竿鄉" },
                    { id: "連江縣-莒光鄉", name: "莒光鄉" },
                    { id: "連江縣-東引鄉", name: "東引鄉" }]
                }, {
                    id: "宜蘭縣",
                    name: "宜蘭縣",
                    children: [{ id: "宜蘭縣-宜蘭市", name: "宜蘭市" },
                    { id: "宜蘭縣-頭城鎮", name: "頭城鎮" },
                    { id: "宜蘭縣-礁溪鄉", name: "礁溪鄉" },
                    { id: "宜蘭縣-壯圍鄉", name: "壯圍鄉" },
                    { id: "宜蘭縣-員山鄉", name: "員山鄉" },
                    { id: "宜蘭縣-羅東鎮", name: "羅東鎮" },
                    { id: "宜蘭縣-三星鄉", name: "三星鄉" },
                    { id: "宜蘭縣-大同鄉", name: "大同鄉" },
                    { id: "宜蘭縣-五結鄉", name: "五結鄉" },
                    { id: "宜蘭縣-冬山鄉", name: "冬山鄉" },
                    { id: "宜蘭縣-蘇澳鎮", name: "蘇澳鎮" },
                    { id: "宜蘭縣-南澳鄉", name: "南澳鄉" }]
                }, {
                    id: "新竹市",
                    name: "新竹市",
                    children: [{ id: "新竹市-東區", name: "東區" },
                    { id: "新竹市-北區", name: "北區" },
                    { id: "新竹市-香山區", name: "香山區" }]
                }, {
                    id: "新竹縣",
                    name: "新竹縣",
                    children: [
                        { id: "新竹縣-竹北市", name: "竹北市" },
                        { id: "新竹縣-湖口鄉", name: "湖口鄉" },
                        { id: "新竹縣-新豐鄉", name: "新豐鄉" },
                        { id: "新竹縣-新埔鎮", name: "新埔鎮" },
                        { id: "新竹縣-關西鎮", name: "關西鎮" },
                        { id: "新竹縣-芎林鄉", name: "芎林鄉" },
                        { id: "新竹縣-寶山鄉", name: "寶山鄉" },
                        { id: "新竹縣-竹東鎮", name: "竹東鎮" },
                        { id: "新竹縣-五峰鄉", name: "五峰鄉" },
                        { id: "新竹縣-橫山鄉", name: "橫山鄉" },
                        { id: "新竹縣-尖石鄉", name: "尖石鄉" },
                        { id: "新竹縣-北埔鄉", name: "北埔鄉" },
                        { id: "新竹縣-峨眉鄉", name: "峨眉鄉" }]
                }, {
                    id: "桃園市",
                    name: "桃園市",
                    children: [{ id: "桃園市-中壢區", name: "中壢區" },
                    { id: "桃園市-平鎮區", name: "平鎮區" },
                    { id: "桃園市-龍潭區", name: "龍潭區" },
                    { id: "桃園市-楊梅區", name: "楊梅區" },
                    { id: "桃園市-新屋區", name: "新屋區" },
                    { id: "桃園市-觀音區", name: "觀音區" },
                    { id: "桃園市-桃園區", name: "桃園區" },
                    { id: "桃園市-龜山區", name: "龜山區" },
                    { id: "桃園市-八德區", name: "八德區" },
                    { id: "桃園市-大溪區", name: "大溪區" },
                    { id: "桃園市-復興區", name: "復興區" },
                    { id: "桃園市-大園區", name: "大園區" },
                    { id: "桃園市-蘆竹區", name: "蘆竹區" }
                    ]
                }, {
                    id: "苗栗縣",
                    name: "苗栗縣",
                    children: [{ id: "苗栗縣-竹南鎮", name: "竹南鎮" },
                    { id: "苗栗縣-頭份鎮", name: "頭份鎮" },
                    { id: "苗栗縣-三灣鄉", name: "三灣鄉" },
                    { id: "苗栗縣-南庄鄉", name: "南庄鄉" },
                    { id: "苗栗縣-獅潭鄉", name: "獅潭鄉" },
                    { id: "苗栗縣-後龍鎮", name: "後龍鎮" },
                    { id: "苗栗縣-通霄鎮", name: "通霄鎮" },
                    { id: "苗栗縣-苑裡鎮", name: "苑裡鎮" },
                    { id: "苗栗縣-苗栗市", name: "苗栗市" },
                    { id: "苗栗縣-造橋鄉", name: "造橋鄉" },
                    { id: "苗栗縣-頭屋鄉", name: "頭屋鄉" },
                    { id: "苗栗縣-公館鄉", name: "公館鄉" },
                    { id: "苗栗縣-大湖鄉", name: "大湖鄉" },
                    { id: "苗栗縣-泰安鄉", name: "泰安鄉" },
                    { id: "苗栗縣-銅鑼鄉", name: "銅鑼鄉" },
                    { id: "苗栗縣-三義鄉", name: "三義鄉" },
                    { id: "苗栗縣-西湖鄉", name: "西湖鄉" },
                    { id: "苗栗縣-卓蘭鎮", name: "卓蘭鎮" }
                    ]
                }, {
                    id: "臺中市",
                    name: "臺中市",
                    children: [{ id: "臺中市-中區", name: "中區" },
                    { id: "臺中市-東區", name: "東區" },
                    { id: "臺中市-南區", name: "南區" },
                    { id: "臺中市-西區", name: "西區" },
                    { id: "臺中市-北區", name: "北區" },
                    { id: "臺中市-北屯區", name: "北屯區" },
                    { id: "臺中市-西屯區", name: "西屯區" },
                    { id: "臺中市-南屯區", name: "南屯區" },
                    { id: "臺中市-太平區", name: "太平區" },
                    { id: "臺中市-大里區", name: "大里區" },
                    { id: "臺中市-霧峰區", name: "霧峰區" },
                    { id: "臺中市-烏日區", name: "烏日區" },
                    { id: "臺中市-豐原區", name: "豐原區" },
                    { id: "臺中市-后里區", name: "后里區" },
                    { id: "臺中市-石岡區", name: "石岡區" },
                    { id: "臺中市-東勢區", name: "東勢區" },
                    { id: "臺中市-和平區", name: "和平區" },
                    { id: "臺中市-新社區", name: "新社區" },
                    { id: "臺中市-潭子區", name: "潭子區" },
                    { id: "臺中市-大雅區", name: "大雅區" },
                    { id: "臺中市-神岡區", name: "神岡區" },
                    { id: "臺中市-大肚區", name: "大肚區" },
                    { id: "臺中市-沙鹿區", name: "沙鹿區" },
                    { id: "臺中市-龍井區", name: "龍井區" },
                    { id: "臺中市-梧棲區", name: "梧棲區" },
                    { id: "臺中市-清水區", name: "清水區" },
                    { id: "臺中市-大甲區", name: "大甲區" },
                    { id: "臺中市-外埔區", name: "外埔區" },
                    { id: "臺中市-大安區", name: "大安區" }
                    ]
                }, {
                    id: "彰化縣",
                    name: "彰化縣",
                    children: [{ id: "彰化縣-彰化市", name: "彰化市" },
                    { id: "彰化縣-芬園鄉", name: "芬園鄉" },
                    { id: "彰化縣-花壇鄉", name: "花壇鄉" },
                    { id: "彰化縣-秀水鄉", name: "秀水鄉" },
                    { id: "彰化縣-鹿港鎮", name: "鹿港鎮" },
                    { id: "彰化縣-福興鄉", name: "福興鄉" },
                    { id: "彰化縣-線西鄉", name: "線西鄉" },
                    { id: "彰化縣-和美鎮", name: "和美鎮" },
                    { id: "彰化縣-伸港鄉", name: "伸港鄉" },
                    { id: "彰化縣-員林鎮", name: "員林鎮" },
                    { id: "彰化縣-社頭鄉", name: "社頭鄉" },
                    { id: "彰化縣-永靖鄉", name: "永靖鄉" },
                    { id: "彰化縣-埔心鄉", name: "埔心鄉" },
                    { id: "彰化縣-溪湖鎮", name: "溪湖鎮" },
                    { id: "彰化縣-大村鄉", name: "大村鄉" },
                    { id: "彰化縣-埔鹽鄉", name: "埔鹽鄉" },
                    { id: "彰化縣-田中鎮", name: "田中鎮" },
                    { id: "彰化縣-北斗鎮", name: "北斗鎮" },
                    { id: "彰化縣-田尾鄉", name: "田尾鄉" },
                    { id: "彰化縣-埤頭鄉", name: "埤頭鄉" },
                    { id: "彰化縣-溪州鄉", name: "溪州鄉" },
                    { id: "彰化縣-竹塘鄉", name: "竹塘鄉" },
                    { id: "彰化縣-二林鎮", name: "二林鎮" },
                    { id: "彰化縣-大城鄉", name: "大城鄉" },
                    { id: "彰化縣-芳苑鄉", name: "芳苑鄉" },
                    { id: "彰化縣-二水鄉", name: "二水鄉" }
                    ]
                }, {
                    id: "南投縣",
                    name: "南投縣",
                    children: [{ id: "南投縣-南投市", name: "南投市" },
                    { id: "南投縣-中寮鄉", name: "中寮鄉" },
                    { id: "南投縣-草屯鎮", name: "草屯鎮" },
                    { id: "南投縣-國姓鄉", name: "國姓鄉" },
                    { id: "南投縣-埔里鎮", name: "埔里鎮" },
                    { id: "南投縣-仁愛鄉", name: "仁愛鄉" },
                    { id: "南投縣-名間鄉", name: "名間鄉" },
                    { id: "南投縣-集集鎮", name: "集集鎮" },
                    { id: "南投縣-水里鄉", name: "水里鄉" },
                    { id: "南投縣-魚池鄉", name: "魚池鄉" },
                    { id: "南投縣-信義鄉", name: "信義鄉" },
                    { id: "南投縣-竹山鎮", name: "竹山鎮" },
                    { id: "南投縣-鹿谷鄉", name: "鹿谷鄉" }
                    ]
                }, {
                    id: "嘉義市",
                    name: "嘉義市",
                    children: [{
                        id: "嘉義市-東區",
                        name: "東區"
                    }, {
                        id: "嘉義市-西區",
                        name: "西區"
                    }]
                }, {
                    id: "嘉義縣",
                    name: "嘉義縣",
                    children: [{ id: "嘉義縣-番路鄉", name: "番路鄉" },
                    { id: "嘉義縣-梅山鄉", name: "梅山鄉" },
                    { id: "嘉義縣-竹崎鄉", name: "竹崎鄉" },
                    { id: "嘉義縣-阿里山鄉", name: "阿里山鄉" },
                    { id: "嘉義縣-中埔鄉", name: "中埔鄉" },
                    { id: "嘉義縣-大埔鄉", name: "大埔鄉" },
                    { id: "嘉義縣-水上鄉", name: "水上鄉" },
                    { id: "嘉義縣-鹿草鄉", name: "鹿草鄉" },
                    { id: "嘉義縣-太保市", name: "太保市" },
                    { id: "嘉義縣-朴子市", name: "朴子市" },
                    { id: "嘉義縣-東石鄉", name: "東石鄉" },
                    { id: "嘉義縣-六腳鄉", name: "六腳鄉" },
                    { id: "嘉義縣-新港鄉", name: "新港鄉" },
                    { id: "嘉義縣-民雄鄉", name: "民雄鄉" },
                    { id: "嘉義縣-大林鎮", name: "大林鎮" },
                    { id: "嘉義縣-溪口鄉", name: "溪口鄉" },
                    { id: "嘉義縣-義竹鄉", name: "義竹鄉" },
                    { id: "嘉義縣-布袋鎮", name: "布袋鎮" }
                    ]
                }, {
                    id: "雲林縣",
                    name: "雲林縣",
                    children: [{ id: "雲林縣-斗南鎮", name: "斗南鎮" },
                    { id: "雲林縣-大埤鄉", name: "大埤鄉" },
                    { id: "雲林縣-虎尾鎮", name: "虎尾鎮" },
                    { id: "雲林縣-土庫鎮", name: "土庫鎮" },
                    { id: "雲林縣-褒忠鄉", name: "褒忠鄉" },
                    { id: "雲林縣-東勢鄉", name: "東勢鄉" },
                    { id: "雲林縣-臺西鄉", name: "臺西鄉" },
                    { id: "雲林縣-崙背鄉", name: "崙背鄉" },
                    { id: "雲林縣-麥寮鄉", name: "麥寮鄉" },
                    { id: "雲林縣-斗六市", name: "斗六市" },
                    { id: "雲林縣-林內鄉", name: "林內鄉" },
                    { id: "雲林縣-古坑鄉", name: "古坑鄉" },
                    { id: "雲林縣-莿桐鄉", name: "莿桐鄉" },
                    { id: "雲林縣-西螺鎮", name: "西螺鎮" },
                    { id: "雲林縣-二崙鄉", name: "二崙鄉" },
                    { id: "雲林縣-北港鎮", name: "北港鎮" },
                    { id: "雲林縣-水林鄉", name: "水林鄉" },
                    { id: "雲林縣-口湖鄉", name: "口湖鄉" },
                    { id: "雲林縣-四湖鄉", name: "四湖鄉" },
                    { id: "雲林縣-元長鄉", name: "元長鄉" }
                    ]
                }, {
                    id: "臺南市",
                    name: "臺南市",
                    children: [{ id: "臺南市-中西區", name: "中西區" },
                    { id: "臺南市-東區", name: "東區" },
                    { id: "臺南市-南區", name: "南區" },
                    { id: "臺南市-北區", name: "北區" },
                    { id: "臺南市-安平區", name: "安平區" },
                    { id: "臺南市-安南區", name: "安南區" },
                    { id: "臺南市-永康區", name: "永康區" },
                    { id: "臺南市-歸仁區", name: "歸仁區" },
                    { id: "臺南市-新化區", name: "新化區" },
                    { id: "臺南市-左鎮區", name: "左鎮區" },
                    { id: "臺南市-玉井區", name: "玉井區" },
                    { id: "臺南市-楠西區", name: "楠西區" },
                    { id: "臺南市-南化區", name: "南化區" },
                    { id: "臺南市-仁德區", name: "仁德區" },
                    { id: "臺南市-關廟區", name: "關廟區" },
                    { id: "臺南市-龍崎區", name: "龍崎區" },
                    { id: "臺南市-官田區", name: "官田區" },
                    { id: "臺南市-麻豆區", name: "麻豆區" },
                    { id: "臺南市-佳里區", name: "佳里區" },
                    { id: "臺南市-西港區", name: "西港區" },
                    { id: "臺南市-七股區", name: "七股區" },
                    { id: "臺南市-將軍區", name: "將軍區" },
                    { id: "臺南市-學甲區", name: "學甲區" },
                    { id: "臺南市-北門區", name: "北門區" },
                    { id: "臺南市-新營區", name: "新營區" },
                    { id: "臺南市-後壁區", name: "後壁區" },
                    { id: "臺南市-白河區", name: "白河區" },
                    { id: "臺南市-東山區", name: "東山區" },
                    { id: "臺南市-六甲區", name: "六甲區" },
                    { id: "臺南市-下營區", name: "下營區" },
                    { id: "臺南市-柳營區", name: "柳營區" },
                    { id: "臺南市-鹽水區", name: "鹽水區" },
                    { id: "臺南市-善化區", name: "善化區" },
                    { id: "臺南市-大內區", name: "大內區" },
                    { id: "臺南市-山上區", name: "山上區" },
                    { id: "臺南市-新市區", name: "新市區" },
                    { id: "臺南市-安定區", name: "安定區" }
                    ]
                }, {
                    id: "高雄市",
                    name: "高雄市",
                    children: [{ id: "高雄市-新興區", name: "新興區" },
                    { id: "高雄市-前金區", name: "前金區" },
                    { id: "高雄市-苓雅區", name: "苓雅區" },
                    { id: "高雄市-鹽埕區", name: "鹽埕區" },
                    { id: "高雄市-鼓山區", name: "鼓山區" },
                    { id: "高雄市-旗津區", name: "旗津區" },
                    { id: "高雄市-前鎮區", name: "前鎮區" },
                    { id: "高雄市-三民區", name: "三民區" },
                    { id: "高雄市-楠梓區", name: "楠梓區" },
                    { id: "高雄市-小港區", name: "小港區" },
                    { id: "高雄市-左營區", name: "左營區" },
                    { id: "高雄市-仁武區", name: "仁武區" },
                    { id: "高雄市-大社區", name: "大社區" },
                    { id: "高雄市-岡山區", name: "岡山區" },
                    { id: "高雄市-路竹區", name: "路竹區" },
                    { id: "高雄市-阿蓮區", name: "阿蓮區" },
                    { id: "高雄市-田寮區", name: "田寮區" },
                    { id: "高雄市-燕巢區", name: "燕巢區" },
                    { id: "高雄市-橋頭區", name: "橋頭區" },
                    { id: "高雄市-梓官區", name: "梓官區" },
                    { id: "高雄市-彌陀區", name: "彌陀區" },
                    { id: "高雄市-永安區", name: "永安區" },
                    { id: "高雄市-湖內區", name: "湖內區" },
                    { id: "高雄市-鳳山區", name: "鳳山區" },
                    { id: "高雄市-大寮區", name: "大寮區" },
                    { id: "高雄市-林園區", name: "林園區" },
                    { id: "高雄市-鳥松區", name: "林園區" },
                    { id: "高雄市-大樹區", name: "大樹區" },
                    { id: "高雄市-旗山區", name: "旗山區" },
                    { id: "高雄市-美濃區", name: "美濃區" },
                    { id: "高雄市-六龜區", name: "六龜區" },
                    { id: "高雄市-內門區", name: "內門區" },
                    { id: "高雄市-杉林區", name: "杉林區" },
                    { id: "高雄市-甲仙區", name: "甲仙區" },
                    { id: "高雄市-桃源區", name: "桃源區" },
                    { id: "高雄市-那瑪夏區", name: "那瑪夏區" },
                    { id: "高雄市-茂林區", name: "茂林區" },
                    { id: "高雄市-茄萣區", name: "茄萣區" }
                    ]
                }, {
                    id: "澎湖縣",
                    name: "澎湖縣",
                    children: [{ id: "澎湖縣-西嶼鄉", name: "西嶼鄉" },
                    { id: "澎湖縣-望安鄉", name: "望安鄉" },
                    { id: "澎湖縣-七美鄉", name: "七美鄉" },
                    { id: "澎湖縣-白沙鄉", name: "白沙鄉" },
                    { id: "澎湖縣-湖西鄉", name: "湖西鄉" }]
                }, {
                    id: "金門縣",
                    name: "金門縣",
                    children: [{ id: "金門縣-金沙鎮", name: "金沙鎮" },
                    { id: "金門縣-金湖鎮", name: "金湖鎮" },
                    { id: "金門縣-金寧鄉", name: "金寧鄉" },
                    { id: "金門縣-金城鎮", name: "金城鎮" },
                    { id: "金門縣-烈嶼鄉", name: "烈嶼鄉" },
                    { id: "金門縣-烏坵鄉", name: "烏坵鄉" }]
                }, {
                    id: "屏東縣",
                    name: "屏東縣",
                    children: [{ id: "屏東縣-屏東市", name: "屏東市" },
                    { id: "屏東縣-三地門鄉", name: "三地門鄉" },
                    { id: "屏東縣-霧臺鄉", name: "霧臺鄉" },
                    { id: "屏東縣-瑪家鄉", name: "瑪家鄉" },
                    { id: "屏東縣-九如鄉", name: "九如鄉" },
                    { id: "屏東縣-里港鄉", name: "里港鄉" },
                    { id: "屏東縣-高樹鄉", name: "高樹鄉" },
                    { id: "屏東縣-鹽埔鄉", name: "鹽埔鄉" },
                    { id: "屏東縣-長治鄉", name: "長治鄉" },
                    { id: "屏東縣-麟洛鄉", name: "麟洛鄉" },
                    { id: "屏東縣-竹田鄉", name: "竹田鄉" },
                    { id: "屏東縣-內埔鄉", name: "內埔鄉" },
                    { id: "屏東縣-萬丹鄉", name: "萬丹鄉" },
                    { id: "屏東縣-潮州鎮", name: "潮州鎮" },
                    { id: "屏東縣-泰武鄉", name: "泰武鄉" },
                    { id: "屏東縣-來義鄉", name: "來義鄉" },
                    { id: "屏東縣-萬巒鄉", name: "萬巒鄉" },
                    { id: "屏東縣-崁頂鄉", name: "崁頂鄉" },
                    { id: "屏東縣-新埤鄉", name: "新埤鄉" },
                    { id: "屏東縣-南州鄉", name: "南州鄉" },
                    { id: "屏東縣-林邊鄉", name: "林邊鄉" },
                    { id: "屏東縣-東港鎮", name: "東港鎮" },
                    { id: "屏東縣-琉球鄉", name: "琉球鄉" },
                    { id: "屏東縣-佳冬鄉", name: "佳冬鄉" },
                    { id: "屏東縣-新園鄉", name: "新園鄉" },
                    { id: "屏東縣-枋寮鄉", name: "枋寮鄉" },
                    { id: "屏東縣-枋山鄉", name: "枋山鄉" },
                    { id: "屏東縣-春日鄉", name: "春日鄉" },
                    { id: "屏東縣-獅子鄉", name: "獅子鄉" },
                    { id: "屏東縣-車城鄉", name: "車城鄉" },
                    { id: "屏東縣-牡丹鄉", name: "牡丹鄉" },
                    { id: "屏東縣-恆春鎮", name: "恆春鎮" },
                    { id: "屏東縣-滿州鄉", name: "滿州鄉" }
                    ]
                }, {
                    id: "臺東縣",
                    name: "臺東縣",
                    children: [{ id: "臺東縣-臺東市", name: "臺東市" },
                    { id: "臺東縣-綠島鄉", name: "綠島鄉" },
                    { id: "臺東縣-蘭嶼鄉", name: "蘭嶼鄉" },
                    { id: "臺東縣-延平鄉", name: "延平鄉" },
                    { id: "臺東縣-卑南鄉", name: "卑南鄉" },
                    { id: "臺東縣-鹿野鄉", name: "鹿野鄉" },
                    { id: "臺東縣-關山鎮", name: "關山鎮" },
                    { id: "臺東縣-海端鄉", name: "海端鄉" },
                    { id: "臺東縣-池上鄉", name: "池上鄉" },
                    { id: "臺東縣-東河鄉", name: "東河鄉" },
                    { id: "臺東縣-成功鎮", name: "成功鎮" },
                    { id: "臺東縣-長濱鄉", name: "長濱鄉" },
                    { id: "臺東縣-太麻里鄉", name: "太麻里鄉" },
                    { id: "臺東縣-金峰鄉", name: "金峰鄉" },
                    { id: "臺東縣-大武鄉", name: "大武鄉" },
                    { id: "臺東縣-達仁鄉", name: "達仁鄉" }
                    ]
                }, {
                    id: "花蓮縣",
                    name: "花蓮縣",
                    children: [{ id: "花蓮縣-花蓮市", name: "花蓮市" },
                    { id: "花蓮縣-新城鄉", name: "新城鄉" },
                    { id: "花蓮縣-秀林鄉", name: "秀林鄉" },
                    { id: "花蓮縣-吉安鄉", name: "吉安鄉" },
                    { id: "花蓮縣-壽豐鄉", name: "壽豐鄉" },
                    { id: "花蓮縣-鳳林鎮", name: "鳳林鎮" },
                    { id: "花蓮縣-光復鄉", name: "光復鄉" },
                    { id: "花蓮縣-豐濱鄉", name: "豐濱鄉" },
                    { id: "花蓮縣-瑞穗鄉", name: "瑞穗鄉" },
                    { id: "花蓮縣-萬榮鄉", name: "萬榮鄉" },
                    { id: "花蓮縣-玉里鎮", name: "玉里鎮" },
                    { id: "花蓮縣-卓溪鄉", name: "卓溪鄉" },
                    { id: "花蓮縣-富里鄉", name: "富里鄉" }
                    ]
                }]
                return commonArray;
            }
        };





    }
}


