const homeRouter = [
  {
    path: '/',
    name: 'login',
    component: () => import('@/views/Login.vue'),
    meta:{
      authority: [
        {
          role: "ALL",
        },
      ],
    }
  },
  { //首頁
    path: '/index',
    name: 'index',
    component: () => import('@/views/Home.vue'),
    meta:{
      authority: [
        {
          role: "S"
        },
        {
          role: "O"
        },
        {
          role: "J"
        },
      ],
    }
  },
  { //事業單位基本資料設定列表
    path: '/orgnization/orgnizationList',
    name: 'orgnizationList',
    component: () => import('@/views/orgnization/orgnizationList.vue'),
    meta:{
      authority: [
        {
          role: "S"
        },
      ],
    }
  },
  { //事業單位基本資料維護
    path: '/orgnization/orgnizationEdit/:orgId',
    name: 'orgnizationEdit',
    component: () => import('@/views/orgnization/orgnizationEdit.vue'),
    meta:{
      authority: [
        {
          role: "S",
          returnPath: "/orgnization/orgnizationList",
        },
        {
          role: "O",
          returnPath: "/index",
        },
        {
          role: "J",
          returnPath: "/index",
        },
      ],
    }
  },
  { //事業單位管理者帳號設定列表
    path: '/member/memberList',
    name: 'memberList',
    component: () => import('@/views/member/memberList.vue'),
    meta:{
      authority: [
        {
          role: "S"
        },
      ],
    }
  },
  { //事業單位管理者帳號編輯
    path: '/member/memberEdit/:uuid',
    name: 'memberEdit',
    component: () => import('@/views/member/memberEdit.vue'),
    meta:{
      authority: [
        {
          role: "S",
          returnPath: "/member/memberList",
        },
        {
          role: "O",
          returnPath: "/index",
        },
      ],
    }
  },
  { //場域管理者帳號設定列表
    path: '/member/jsMemberList',
    name: 'memberList',
    component: () => import('@/views/member/jsMemberList.vue'),
    meta:{
      authority: [
        {
          role: "S"
        },
        {
          role: "O"
        },
      ],
    }
  },
  { //場域管理者帳號維護
    path: '/member/jsMemberEdit/:uuid',
    name: 'memberEdit',
    component: () => import('@/views/member/jsMemberEdit.vue'),
    meta:{
      authority: [
        {
          role: "S",
          returnPath: "/member/jsMemberList",
        },
        {
          role: "O",
          returnPath: "/member/jsMemberList",
        },
        {
          role: "J",
          returnPath: "/index",
        },
      ],
    }
  },
  { //事業單位人員維護列表
    path: '/orgnizationMember/orgnizationMemberList',
    name: 'orgnizationMemberList',
    component: () => import('@/views/orgnizationMember/orgnizationMemberList.vue'),
    meta:{
      authority: [
        {
          role: "S",
        },
        {
          role: "O",
        },
        {
          role: "J",
        },
      ],
    }
  },
  { //事業單位人員維護
    path: '/orgnizationMember/orgnizationMemberEdit/:orgmId',
    name: 'orgnizationMemberEdit',
    component: () => import('@/views/orgnizationMember/orgnizationMemberEdit.vue'),
    meta:{
      authority: [
        {
          role: "S",
        },
        {
          role: "O",
        },
        {
          role: "J",
        },
      ],
    }
  },
  { //場域設定及管理列表
    path: '/jobSite/jobSiteList',
    name: 'jobSiteList',
    component: () => import('@/views/jobSite/jobSiteList.vue'),
    meta:{
      authority: [
        {
          role: "S",
        },
        {
          role: "O",
        },
        {
          role: "J",
        },
      ],
    }
  },
  { //場域設定及管理維護
    path: '/jobSite/jobSiteEdit/:jsId',
    name: 'jobSiteEdit',
    component: () => import('@/views/jobSite/jobSiteEdit.vue'),
    meta:{
      authority: [
        {
          role: "S",
        },
        {
          role: "O",
        },
        {
          role: "J",
        },
      ],
    }
  },
  { //場域設定及管理維護-人員管理
    path: '/jobSite/jobSiteMemberList/:orgId/:jsId',
    name: 'jobSiteMemberList',
    component: () => import('@/views/jobSite/jobSiteMemberList.vue'),
    meta:{
      authority: [
        {
          role: "S",
        },
        {
          role: "O",
        },
        {
          role: "J",
        },
      ],
    }
  },
  { //場域設定及管理維護-訊息通知
    path: '/jobSite/jobSiteNotification/:jsId',
    name: 'jobSiteNotification',
    component: () => import('@/views/jobSite/jobSiteNotification.vue'),
    meta:{
      authority: [
        {
          role: "S",
        },
        {
          role: "O",
        },
        {
          role: "J",
        },
      ],
    }
  },
  { //人員進出自動辨識列表
    path: '/inout/inoutList',
    name: 'inoutList',
    component: () => import('@/views/inout/inoutList.vue'),
    meta:{
      authority: [
        {
          role: "S",
        },
        {
          role: "O",
        },
        {
          role: "J",
        },
      ],
    }
  },
  { //人員進出自動辨識
    path: '/inout/inout/:data',
    name: 'inout',
    component: () => import('@/views/inout/inout.vue'),
    meta:{
      authority: [
        {
          role: "ALL",
        },
      ],
    }
  },
  { //參數設定列表
    path: '/parameter/paraList/:paraId',
    name: 'paraList',
    component: () => import('@/views/parameter/paraList.vue'),
    meta:{
      authority: [
        {
          role: "S",
        },
      ],
    }
  },
  { //參數設定編輯
    path: '/parameter/paraEdit/:mainParamId/:paraData',
    name: 'paraEdit',
    component: () => import('@/views/parameter/paraEdit.vue'),
    meta:{
      authority: [
        {
          role: "S",
        },
      ],
    }
  },
  { //儀表板1
    path: '/dashbord/:jsId',
    name: 'dashbord',
    component: () => import('@/views/dashbord/dashbord.vue'),
    meta:{
      authority: [
        {
          role: "ALL",
        },
      ],
    }
  },
  { //儀表板2
    path: '/dashbordInfo/:jsId',
    name: 'dashbordInfo',
    component: () => import('@/views/dashbord/dashbordInfo.vue'),
    meta:{
      authority: [
        {
          role: "ALL",
        },
      ],
    }
  },
  { //入出紀錄清單
    path: '/inoutRecord/inoutRecordList/:orgId/:jsId/:selectDate',
    name: 'inoutRecordList',
    component: () => import('@/views/inoutRecord/inoutRecordList.vue'),
    meta:{
      authority: [
        {
          role: "S",
        },
        {
          role: "O",
        },
        {
          role: "J",
        },
      ],
    }
  },
  { //入出紀錄
    path: '/inoutRecord/inoutRecord',
    name: 'inoutRecord',
    component: () => import('@/views/inoutRecord/inoutRecord.vue'),
    meta:{
      authority: [
        {
          role: "S",
        },
        {
          role: "O",
        },
        {
          role: "J",
        },
      ],
    }
  },
  { //入出辨識調校清單
    path: '/inoutRecord/inoutDetectRecordList',
    name: 'inoutDetectRecordList',
    component: () => import('@/views/inoutRecord/inoutDetectRecordList.vue'),
    meta:{
      authority: [
        {
          role: "S",
        },
        {
          role: "O",
        },
        {
          role: "J",
        },
      ],
    }
  },
  { //入出辨識訓練
    path: '/inoutRecord/inoutDetectTraning/:drId',
    name: 'inoutDetectTraning',
    component: () => import('@/views/inoutRecord/inoutDetectTraning.vue'),
    meta:{
      authority: [
        {
          role: "S",
        },
      ],
    }
  },
  { //條碼辨識
    path: '/contact/barcodeInout/:data',
    name: 'barcodeInout',
    component: () => import('@/views/contact/barcodeInout.vue'),
    meta:{
      authority: [
        {
          role: "ALL",
        },
      ],
    }
  },
  { //條碼辨識
    path: '/contact/barcodeTest',
    name: 'barcodeTest',
    component: () => import('@/views/contact/barcodeTest.vue'),
    meta:{
      authority: [
        {
          role: "ALL",
        },
      ],
    }
  },
  { //個人QRcode
    path: '/contact/personalQR',
    name: 'personalQR',
    component: () => import('@/views/contact/personalQR.vue'),
    meta:{
      authority: [
        {
          role: "ALL",
        },
      ],
    }
  },
  {
    path: '/redirect',
    beforeEnter() {
        window.location.replace("/index")
    }
  },
]

export default homeRouter

