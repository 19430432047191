import { camelCase } from 'lodash-es'
const requiredModules = require.context('./modules', false, /\.js$/)
//const requiredModules = require.context('./modules', false, /\.js$/)
const routers = {}

requiredModules.keys().forEach(fileName => {
  // 不載入index.js
  if (fileName === './index.js') return
  // 轉為駝峰命名
  const moduleName = camelCase(fileName.replace(/(\.\/|\.js)/g, ''))

  routers[moduleName] =
    requiredModules(fileName).default || requiredModules(fileName)
})
export default routers