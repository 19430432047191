import Vue from 'vue'
import Router from 'vue-router'
import routers from '@/router/index'
Vue.use(Router)
let routes = []
Object.values(routers).forEach(router => {
  routes.push(...router)
})

export default new Router({
  base: process.env.BASE_URL, //項目名稱 訪問路由頁面都需要加上這個，訪問的根路徑為http://ip:port/webSite
  mode: 'history',
  routes
})